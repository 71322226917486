<template>
  <v-layout>
    <v-app-bar app height="124" class="align-start elevation-1">
      <v-col cols="12" class="py-0">
        <v-row dense class="align-center flex-nowrap">
          <v-col cols="auto" class="d-flex d-lg-none pa-0">
            <!--Platzhalter für mobile Menu-->
            <div style="width: 50px"></div>
          </v-col>
          <v-col cols="auto">
            <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn outlined color="red lighten-1" @click="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon>
              <span class="ml-1 d-none d-lg-flex">Zurück</span>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="green lighten-1 grey--text text--lighten-4"
              @click="artikelSpeichern()"
              :disabled="validDatasets || !selectedBearbeiter.Bearbeiter_ID"
            >
              <v-icon>mdi-check</v-icon>
              <span :loading="buttonLoading" class="ml-1 d-none d-lg-flex">Importieren</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="align-center">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-select
              hide-details
              dense
              outlined
              required
              placeholder="Bearbeiter(*)"
              v-model="selectedBearbeiter.Bearbeiter_ID"
              :items="Bearbeiter"
              return-object
              item-text="Vorname"
              item-value="Bearbeiter_ID"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-container fluid class="px-1">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel v-for="newdataset in newDatasets" :key="newdataset.ID">
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <v-row dense align="center">
                    <v-col cols="auto">
                      <v-fade-transition leave-absolute>
                        <span v-if="open" key="0">
                          <v-icon
                            v-if="
                              newdataset.Artikelnummer &&
                                newdataset.Artikelnummer !== null &&
                                newdataset.Größe &&
                                newdataset.Größe !== null &&
                                newdataset.Name &&
                                newdataset.Name !== null &&
                                newdataset.nameExists !== true
                            "
                            color="green lighten-2"
                            >mdi-check</v-icon
                          >
                          <div v-else>
                            <v-icon color="red lighten-2">mdi-alert</v-icon>
                          </div>
                        </span>
                        <span v-else key="1">
                          <v-icon
                            v-if="
                              newdataset.Artikelnummer &&
                                newdataset.Artikelnummer !== null &&
                                newdataset.Größe &&
                                newdataset.Größe !== null &&
                                newdataset.Name &&
                                newdataset.Name !== null &&
                                newdataset.nameExists !== true
                            "
                            color="green lighten-2"
                            >mdi-check</v-icon
                          >
                          <div v-else>
                            <v-icon color="red lighten-2">mdi-alert</v-icon>
                            <v-btn icon color="red lighten-2" @click="removeArticle(newdataset.ID)"><v-icon>mdi-delete</v-icon></v-btn>
                          </div>
                        </span>
                      </v-fade-transition>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3">
                      <v-fade-transition leave-absolute>
                        <span v-if="open" key="0">
                          <span
                            v-if="
                              newdataset.Artikelnummer &&
                                newdataset.Artikelnummer !== null &&
                                newdataset.Größe &&
                                newdataset.Größe !== null &&
                                newdataset.Name &&
                                newdataset.Name !== null &&
                                newdataset.nameExists !== true
                            "
                          >
                            Alle notwendigen Artikeldaten sind vorhanden!
                          </span>
                          <span
                            v-else-if="
                              newdataset.Artikelnummer &&
                                newdataset.Artikelnummer !== null &&
                                newdataset.Größe &&
                                newdataset.Größe !== null &&
                                newdataset.Name &&
                                newdataset.Name !== null &&
                                newdataset.nameExists !== false
                            "
                          >
                            Dier Artikelname wurde bereits vergeben!
                          </span>
                          <span v-else>
                            Bitte alle mit (*) gekennzeichneten Artikeldaten eintragen!
                          </span>
                        </span>
                        <span v-else key="1">
                          <span class="font-weight-bold">Art.Nr.: </span>
                          <span>{{ newdataset.Artikelnummer }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                    <v-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <v-fade-transition leave-absolute>
                        <span v-if="open" key="0"><v-btn outlined color="primary" @click="copyArticle(newdataset)">Kopieren</v-btn></span>
                        <span v-else key="1">
                          <span class="font-weight-bold">Artikelname: </span>
                          <span v-if="newdataset.Name && newdataset.Name.Name">{{ newdataset.Name.Name }}</span>
                          <span v-else>{{ newdataset.Name }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                    <v-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <v-fade-transition leave-absolute>
                        <span v-if="open" key="0"><v-btn outlined color="primary" @click="removeArticle(newdataset.ID)">Entfernen</v-btn></span>
                        <span v-else key="1">
                          <span class="font-weight-bold">Größe: </span>
                          <span>{{ newdataset.Größe }}</span>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="subtitle-2">
                <v-col cols="12">
                  <v-row dense align="center">
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="7" sm="7">
                      <span class="font-weight-bold">Art.-Nr.(*)</span>
                      <v-text-field v-model="newdataset.Artikelnummer" hide-details dense outlined required clearable placeholder="Artikelnummer">
                      </v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Gruppe</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        required
                        open-on-clear
                        v-model="newdataset.Artikelgruppe_ID"
                        :items="filteredArtikelgruppen"
                        item-text="Artikelgruppe"
                        item-value="Artikelgruppe_ID"
                        placeholder="Gruppe"
                        clearable
                        :disabled="!Abteilung_ID"
                        @click:clear="clearSubgroup(newdataset)"
                        ><template v-slot:no-data>
                          <span class="text-center d-block">nicht vorhanden</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Untergruppe</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        required
                        open-on-clear
                        clearable
                        v-model="newdataset.Artikeluntergruppe_ID"
                        :items="newdataset.Artikeluntergruppen"
                        item-text="Artikeluntergruppe"
                        item-value="Artikeluntergruppe_ID"
                        placeholder="Untergruppe"
                        :disabled="!newdataset.Artikelgruppe_ID"
                        ><template v-slot:no-data>
                          <span class="text-center d-block">nicht vorhanden</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row dense align="center">
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Größe(*)</span>
                      <v-text-field
                        v-model="newdataset.Größe"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Größe"
                        @click:clear="clearName(newdataset)"
                        @change="clearName(newdataset)"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Artikelname(*)</span>
                      <v-combobox
                        :disabled="!newdataset.Größe"
                        v-model="newdataset.Name"
                        :items="filteredAvailableArtikelnamen(newdataset)"
                        item-value="Artikelname_ID"
                        item-text="Name"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Artikelname"
                        @input="checkNames(newdataset)"
                        @click:clear="$nextTick(() => setUsedArticleNames())"
                        :error="newdataset.nameExists"
                      >
                      </v-combobox>
                    </v-col>
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Hersteller</span>
                      <v-select
                        v-model="newdataset.Hersteller_ID"
                        :items="Hersteller"
                        item-text="Name"
                        item-value="Hersteller_ID"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Hersteller"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row dense align="start">
                    <v-col class="pb-0 pt-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Farben</span>
                      <v-select
                        v-model="newdataset.Hauptfarbe_ID"
                        :items="Hauptfarben"
                        item-text="Farbe"
                        item-value="Farb_ID"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Hauptfarbe"
                      >
                      </v-select>
                      <div class="pt-1" v-if="newdataset.Hauptfarbe_ID">
                        <v-select
                          v-model="newdataset.Nebenfarbe_ID"
                          :items="Nebenfarben"
                          item-text="Farbe"
                          item-value="Farb_ID"
                          hide-details
                          dense
                          outlined
                          required
                          clearable
                          placeholder="Nebenfarbe"
                        >
                        </v-select>
                      </div>
                    </v-col>
                    <v-col class="pb-0 pt-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Stoffe</span>
                      <v-select
                        v-model="newdataset.Hauptstoff_ID"
                        :items="Hauptstoffe"
                        item-text="Stoff"
                        item-value="Stoff_ID"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Hauptstoff"
                      >
                      </v-select>
                      <div class="pt-1" v-if="newdataset.Hauptstoff_ID">
                        <v-select
                          v-model="newdataset.Nebenstoff_ID"
                          :items="Nebenstoffe"
                          item-text="Stoff"
                          item-value="Stoff_ID"
                          hide-details
                          dense
                          outlined
                          required
                          clearable
                          placeholder="Nebenstoff"
                        >
                        </v-select>
                      </div>
                    </v-col>
                    <v-col class="py-1" cols="12" xl="4" lg="4" md="6" sm="6">
                      <span class="font-weight-bold">Schnitt</span>
                      <v-select
                        v-model="newdataset.Schnitt_ID"
                        :items="Schnitte"
                        item-text="Schnitt"
                        item-value="Schnitt_ID"
                        hide-details
                        dense
                        outlined
                        required
                        clearable
                        placeholder="Schnitt"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row dense align="start">
                    <v-col class="py-1" cols="12" xl="8" lg="8" md="6" sm="6">
                      <span class="font-weight-bold">Besonderheiten</span>
                      <v-textarea v-model="newdataset.Besonderheiten" dense hide-details outlined rows="2" clearable></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense align="start">
                    <v-col class="py-1" cols="6" xl="3" lg="3" md="3" sm="5">
                      <span class="font-weight-bold">VK brutto</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="newdataset.Berechnungen.VK_brutto"
                        placeholder="0,00"
                        suffix="€"
                        @change="Kalkulation_Berechnung(newdataset)"
                        @click:clear="$nextTick(() => clearVKbrutto(newdataset))"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="6" xl="3" lg="3" md="3" sm="5">
                      <span class="font-weight-bold">Leihpreis</span>
                      <v-text-field
                        hide-details
                        dense
                        outlined
                        clearable
                        v-model="newdataset.Berechnungen.Leihpreis"
                        placeholder="0,00"
                        suffix="€"
                        @change="$nextTick(() => formatLeihpreis(newdataset))"
                        @click:clear="$nextTick(() => clearLeihpreis(newdataset))"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="py-1" cols="6" xl="3" lg="3" md="3" sm="5">
                      <span class="font-weight-bold">Bestand</span>
                      <v-text-field hide-details dense outlined v-model="newdataset.Bestand" placeholder="Bestand" suffix="Stk."> </v-text-field>
                      <div class="pt-1" v-if="newdataset.ist_bestandsgeführt === 1">
                        <v-text-field
                          hide-details
                          dense
                          outlined
                          clearable
                          v-model="newdataset.Mindestbestand"
                          placeholder="Mindestbestand"
                          suffix="Stk."
                        >
                        </v-text-field>
                      </div>
                    </v-col>
                    <v-col class="py-1" cols="6" xl="3" lg="3" md="3" sm="5">
                      <span class="font-weight-bold">bestandsgeführt</span>
                      <v-checkbox
                        dense
                        hide-details
                        class="pb-1 pt-1 mt-0"
                        v-model="newdataset.ist_bestandsgeführt"
                        :true-value="1"
                        :false-value="0"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn outlined color="green lighten-2" @click="addDefaultArticle"><v-icon left>mdi-plus</v-icon>weiteren Artikel importieren</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <LeavePathWrapper ref="leavePath">
      <LeavePathDialog />
    </LeavePathWrapper>
  </v-layout>
</template>

<script>
import LeavePathWrapper from "../../../components/dialogs/LeavePathWrapper.vue";
import LeavePathDialog from "../../../components/dialogs/LeavePathDialog.vue";

export default {
  components: {
    LeavePathWrapper,
    LeavePathDialog,
  },

  mounted() {
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (this.newDatasets.length === 0) {
      next();
    } else if (this.newDatasets.length > 0) {
      this.$refs.leavePath.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    }
  },

  data() {
    return {
      buttonLoading: false,
      incrementID: 1,
      Abteilung_ID: 2,
      Artikelgruppen: [],
      Artikeluntergruppen: [],
      Bestellstatus: [],
      Artikelnamen: [],
      Artikelnamenliste: [],
      usedArticleNames: [],
      nameExists: false,
      Hauptfarben: [],
      Nebenfarben: [],
      Hauptstoffe: [],
      Nebenstoffe: [],
      Schnitte: [],
      Hersteller: [],
      Einheiten: [],
      MwSt: [],
      Bearbeiter: [],
      selectedBearbeiter: {},
      newDatasets: [],
      defaultArticle: {
        Abteilung_ID: 3,
        Artikelgruppe_ID: null,
        Artikeluntergruppe_ID: null,
        Artikelnummer: null,
        Name: null,
        Größe: null,
        Status_ID: 2,
        Bestellstatus_ID: 2,
        Hauptfarbe_ID: null,
        Nebenfarbe_ID: null,
        Hauptstoff_ID: null,
        Nebenstoff_ID: null,
        Schnitt_ID: null,
        Besonderheiten: null,
        Hersteller_ID: null,
        VK_netto: "",
        VK_brutto: "",
        Leihpreis: "",
        EK_netto: "",
        EK_netto_spezial: "",
        EK_brutto: "",
        Kalkulation: null,
        ist_bestandsgeführt: 0,
        Bestand: 1,
        Einheit_ID: 1,
        Mindestbestand: null,
        Bild: null,
        Anzahl_verliehen: 0,
        Berechnungen: {
          EK_netto: "",
          EK_netto_spezial: "",
          VK_brutto: "",
          Kalkulation: "",
          EK_brutto: "",
          VK_netto: "",
          Leihpreis: "",
          MwSt: {
            MwSt_ID: 1,
            MwSt: "19%",
            Satz: 1.19,
          },
        },
      },
      initialArticle: {
        Abteilung_ID: 3,
        Artikelgruppe_ID: null,
        Artikeluntergruppe_ID: null,
        Artikelnummer: null,
        Name: null,
        Größe: null,
        Status_ID: 2,
        Bestellstatus_ID: 2,
        Hauptfarbe_ID: null,
        Nebenfarbe_ID: null,
        Hauptstoff_ID: null,
        Nebenstoff_ID: null,
        Schnitt_ID: null,
        Besonderheiten: null,
        Hersteller_ID: null,
        VK_netto: "",
        VK_brutto: "",
        Leihpreis: "",
        EK_netto: "",
        EK_netto_spezial: "",
        EK_brutto: "",
        Kalkulation: null,
        ist_bestandsgeführt: 0,
        Bestand: 1,
        Einheit_ID: 1,
        Mindestbestand: null,
        Bild: null,
        Anzahl_verliehen: 0,
        Berechnungen: {
          EK_netto: "",
          EK_netto_spezial: "",
          VK_brutto: "",
          Kalkulation: "",
          EK_brutto: "",
          VK_netto: "",
          Leihpreis: "",
          MwSt: {
            MwSt_ID: 1,
            MwSt: "19%",
            Satz: 1.19,
          },
        },
      },
    };
  },

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    validDatasets() {
      const invalidinserts = this.newDatasets.filter(
        (item) =>
          !item.Artikelnummer ||
          item.Artikelnummer === null ||
          !item.Name ||
          item.Name === null ||
          !item.Größe ||
          item.Größe === null ||
          item.nameExists === true
      );
      if (invalidinserts.length > 0) {
        return true;
      } else return false;
    },

    filteredArtikelgruppen() {
      if (this.Abteilung_ID) {
        return this.Artikelgruppen.filter((item) => item.Abteilung_ID === this.Abteilung_ID);
      } else return [];
    },

    filteredArtikelnamenByUsed() {
      if (this.usedArticleNames.length > 0) {
        return this.Artikelnamenliste.filter((it) => this.usedArticleNames.indexOf(it.Name) === -1);
      } else return this.Artikelnamenliste;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/insert`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.newDatasets.push(this.defaultArticle);
      Object.assign(this.defaultArticle, { ID: 0 });
    },

    addDefaultArticle() {
      var datasetlength = this.incrementID;
      var emptyArticle = {
        Abteilung_ID: 3,
        Artikelgruppe_ID: null,
        Artikeluntergruppe_ID: null,
        Artikelnummer: null,
        Name: null,
        Größe: null,
        Status_ID: 2,
        Bestellstatus_ID: 2,
        Hauptfarbe_ID: null,
        Nebenfarbe_ID: null,
        Hauptstoff_ID: null,
        Nebenstoff_ID: null,
        Schnitt_ID: null,
        Besonderheiten: null,
        Hersteller_ID: null,
        VK_netto: "",
        VK_brutto: "",
        Leihpreis: "",
        EK_netto: "",
        EK_netto_spezial: "",
        EK_brutto: "",
        Kalkulation: null,
        ist_bestandsgeführt: 0,
        Bestand: 1,
        Einheit_ID: 1,
        Mindestbestand: null,
        Bild: null,
        Anzahl_verliehen: 0,
        Berechnungen: {
          EK_netto: "",
          EK_netto_spezial: "",
          VK_brutto: "",
          Kalkulation: "",
          EK_brutto: "",
          VK_netto: "",
          Leihpreis: "",
          MwSt: {
            MwSt_ID: 1,
            MwSt: "19%",
            Satz: 1.19,
          },
        },
        nameExists: false,
      };
      Object.assign(emptyArticle, { ID: datasetlength });
      this.newDatasets.push(emptyArticle);
      this.incrementID = this.incrementID + 1;
    },

    removeArticle(id) {
      var index = this.newDatasets
        .map((x) => {
          return x.ID;
        })
        .indexOf(id);

      this.newDatasets.splice(index, 1);
      this.setUsedArticleNames();
    },

    copyArticle(dataset) {
      var datasetlength = this.incrementID;
      var emptyArticle = {};
      delete dataset.ID;
      emptyArticle = Object.assign({}, dataset);
      delete emptyArticle.Name;
      Object.assign(emptyArticle, { ID: datasetlength });
      this.newDatasets.push(emptyArticle);
      this.incrementID = this.incrementID + 1;
    },

    filteredArtikeluntergruppen(newdataset) {
      if (newdataset.Artikelgruppe_ID) {
        newdataset.Artikeluntergruppen = this.Artikeluntergruppen.filter((item) => item.Artikelgruppe_ID === newdataset.Artikelgruppe_ID);
      } else newdataset.Artikelgruppen = [];
    },

    filteredAvailableArtikelnamen(article) {
      if (article.Abteilung_ID && article.Größe) {
        return this.filteredArtikelnamenByUsed.filter((item) => item.Abteilung_ID === this.Abteilung_ID && item.Größe === article.Größe);
      } else return this.filteredArtikelnamenByUsed.filter((item) => item.Abteilung_ID === this.Abteilung_ID);
    },

    checkNames(newdataset) {
      if (newdataset.Name) {
        const findname1 = this.Artikelnamen.filter((item) => item.Artikelname === newdataset.Name && item.Artikelname !== null);

        const findname2 = this.usedArticleNames.filter((item) => item === newdataset.Name && item !== null);

        if (findname1.length > 0 || findname2.length > 0) {
          newdataset.nameExists = true;
        } else newdataset.nameExists = false;
      } else if (newdataset.Name && newdataset.Name.Name) {
        const findname1 = this.Artikelnamen.filter((item) => item.Artikelname === newdataset.Name.Name && item.Artikelname !== null);

        const findname2 = this.usedArticleNames.filter((item) => item === newdataset.Name.Name && item !== null);

        if (findname1.length > 0 || findname2.length > 0) {
          newdataset.nameExists = true;
        } else newdataset.nameExists = false;
      } else newdataset.nameExists = false;

      this.setUsedArticleNames(newdataset);
    },

    clearSubgroup(newdataset) {
      newdataset.Artikeluntergruppe_ID = null;
    },

    clearName(newdataset) {
      newdataset.Name = null;
      this.setUsedArticleNames();
    },

    setUsedArticleNames() {
      var usedArticleNamesAll = [];
      const newdatasets = this.newDatasets;

      newdatasets.forEach(function(article) {
        if (article.Name && article.Name.Name) {
          usedArticleNamesAll.push(article.Name.Name);
        } else {
          usedArticleNamesAll.push(article.Name);
        }
      });

      this.usedArticleNames = usedArticleNamesAll;
    },

    VK_brutto_Berechnung(newdataset) {
      if (newdataset.Berechnungen.EK_netto) {
        const eknetto = newdataset.Berechnungen.EK_netto;
        const kalk = newdataset.Berechnungen.Kalkulation;
        const mwst = newdataset.Berechnungen.MwSt.Satz;

        const eknetto_string = String(parseFloat(eknetto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const ekbrutto = parseFloat(eknetto.replace(",", ".") * mwst);
        const ekbrutto_string = String(ekbrutto.toFixed(2)).replace(".", ",");

        const vknetto = parseFloat(eknetto.replace(",", ".") * kalk);
        const vknetto_string = String(vknetto.toFixed(2)).replace(".", ",");

        const vkbrutto = (vknetto * mwst).toFixed(2);
        const vkbrutto_string = String(vkbrutto.replace(".", ","));

        const leihpreis = (vkbrutto / 3).toFixed(2);
        const leihpreis_string = String(leihpreis.replace(".", ","));

        newdataset.Berechnungen.EK_netto = eknetto_string;
        newdataset.Berechnungen.EK_brutto = ekbrutto_string;
        newdataset.Berechnungen.VK_brutto = vkbrutto_string;
        newdataset.Berechnungen.VK_netto = vknetto_string;
        newdataset.Berechnungen.Leihpreis = leihpreis_string;
      }
    },

    Kalkulation_Berechnung(newdataset) {
      if (newdataset.Berechnungen.EK_netto && newdataset.Berechnungen.VK_brutto) {
        const vkbrutto = newdataset.Berechnungen.VK_brutto;
        const mwst = newdataset.Berechnungen.MwSt.Satz;
        const eknetto = newdataset.Berechnungen.EK_netto;

        const vknetto = parseFloat(vkbrutto.replace(",", ".")) / mwst;
        const vknetto_string = String(vknetto.toFixed(2).replace(".", ","));

        const vkbrutto_string = String(parseFloat(vkbrutto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const kalk = parseFloat(vkbrutto.replace(",", ".")) / (mwst * eknetto.replace(",", "."));

        const leihpreis = parseFloat(vkbrutto.replace(",", ".")) / 3;
        const leihpreis_string = String(leihpreis.toFixed(2)).replace(".", ",");

        newdataset.Berechnungen.VK_brutto = vkbrutto_string;
        newdataset.Berechnungen.Kalkulation = kalk;
        newdataset.Berechnungen.VK_netto = vknetto_string;
        newdataset.Berechnungen.Leihpreis = leihpreis_string;
      } else if (!newdataset.Berechnungen.EK_netto && newdataset.Berechnungen.VK_brutto) {
        const mwst = newdataset.Berechnungen.MwSt.Satz;
        const vkbrutto = newdataset.Berechnungen.VK_brutto;
        const vkbrutto_string = String(parseFloat(vkbrutto.replace(",", ".")).toFixed(2)).replace(".", ",");

        const vknetto = parseFloat(vkbrutto.replace(",", ".")) / mwst;
        const vknetto_string = String(vknetto.toFixed(2).replace(".", ","));

        newdataset.Berechnungen.VK_netto = vknetto_string;
        newdataset.Berechnungen.VK_brutto = vkbrutto_string;
      } else if (newdataset.Berechnungen.EK_netto && !newdataset.Berechnungen.VK_brutto) {
      }
    },

    formatLeihpreis(newdataset) {
      if (newdataset.Berechnungen.Leihpreis) {
        const leihpreis = newdataset.Berechnungen.Leihpreis;
        const leihpreis_string = String(parseFloat(leihpreis.replace(",", ".")).toFixed(2)).replace(".", ",");
        newdataset.Berechnungen.Leihpreis = leihpreis_string;
      }
    },

    formatEKnettoSpezial(newdataset) {
      if (newdataset.Berechnungen.EK_netto_spezial !== null || newdataset.Berechnungen.EK_netto_spezial !== "") {
        const EK_netto_spezial = newdataset.Berechnungen.EK_netto_spezial;
        const EK_netto_spezial_string = String(parseFloat(EK_netto_spezial.replace(",", ".")).toFixed(2)).replace(".", ",");
        newdataset.Berechnungen.EK_netto_spezial = EK_netto_spezial_string;
      } else newdataset.Berechnungen.EK_netto_spezial = "";
    },

    clearLeihpreis(newdataset) {
      newdataset.newDataset.Leihpreis = null;
    },

    clearBestand(newdataset) {
      newdataset.newDataset.Bestand = null;
    },

    clearMindestbestand(newdataset) {
      newdataset.newDataset.Mindestbestand = null;
    },

    clearEKnetto(newdataset) {
      newdataset.Berechnungen.EK_netto = "";
      newdataset.Berechnungen.EK_brutto = "";
      newdataset.Berechnungen.Kalkulation = 2;
      newdataset.Berechnungen.EK_netto_spezial = "";
      newdataset.Ersparnis = "";
    },

    clearEKnettospezial(newdataset) {
      newdataset.Berechnungen.EK_netto_spezial = "";
      newdataset.Ersparnis = "";
    },

    clearVKbrutto(newdataset) {
      newdataset.Berechnungen.VK_brutto = "";
      newdataset.Berechnungen.VK_netto = "";
      newdataset.Berechnungen.Kalkulation = 2;
    },

    clearLeihpreis(newdataset) {
      newdataset.Berechnungen.Leihpreis = "";
    },

    async artikelSpeichern() {
      this.buttonLoading = true;
      const multipleArticlesAdd = this.newDatasets;
      const amountDatasets = multipleArticlesAdd.length;
      const bearbeiterid = this.selectedBearbeiter.Bearbeiter_ID;

      multipleArticlesAdd.forEach(async function(article) {
        var articlenameid = null;
        var articlename = null;
        const articleNameToAdd = {};

        if (article.Name && article.Name.Artikelname_ID) {
          articlenameid = article.Name.Artikelname_ID;
          articlename = article.Name.Name;
        } else if (article.Name === null) {
          articlenameid = null;
        } else {
          articlename = article.Name;
          if (Object.keys(articleNameToAdd).length === 0) {
            Object.assign(articleNameToAdd, {
              Abteilung_ID: 3,
              Artikelgruppe_ID: article.Artikelgruppe_ID,
              Größe: article.Größe,
              Artikelname: articlename,
              verwendbar: 1,
            });
          }

          const saveresponse = await fetch(`/api/database/articles/name/insert`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(articleNameToAdd),
          });
          const jsonartikel = await saveresponse.json();
          articlenameid = jsonartikel.lastInsertRowid;
        }

        var bestellstatusid = 2;

        if (article.Hersteller_ID === null) {
          var bestellstatusid = 1;
        }

        const articleToAdd = {};
        if (Object.keys(articleToAdd).length === 0) {
          Object.assign(articleToAdd, {
            Abteilung_ID: 3,
            Artikelgruppe_ID: article.Artikelgruppe_ID,
            Artikeluntergruppe_ID: article.Artikeluntergruppe_ID,
            Artikelnummer: article.Artikelnummer,
            Artikelname_ID: articlenameid,
            Größe: article.Größe,
            Status_ID: article.Status_ID,
            Bestellstatus_ID: bestellstatusid,
            Hauptfarbe_ID: article.Hauptfarbe_ID,
            Nebenfarbe_ID: article.Nebenfarbe_ID,
            Hauptstoff_ID: article.Hauptstoff_ID,
            Nebenstoff_ID: article.Nebenstoff_ID,
            Schnitt_ID: article.Schnitt_ID,
            Besonderheiten: article.Besonderheiten,
            Hersteller_ID: article.Hersteller_ID,
            VK_netto: article.Berechnungen.VK_netto,
            VK_brutto: article.Berechnungen.VK_brutto,
            Leihpreis: article.Berechnungen.Leihpreis,
            EK_netto: article.Berechnungen.EK_netto,
            EK_netto_spezial: article.Berechnungen.EK_netto_spezial,
            EK_brutto: article.Berechnungen.EK_brutto,
            MwSt_ID: article.Berechnungen.MwSt.MwSt_ID,
            Kalkulation: article.Kalkulation,
            ist_bestandsgeführt: article.ist_bestandsgeführt,
            Bestand: article.Bestand,
            Einheit_ID: article.Einheit_ID,
            Mindestbestand: article.Mindestbestand,
            Bild: null,
            Anzahl_verliehen: 0,
          });
        }

        const saveresponse = await fetch(`/api/database/articles/dataset/insert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleToAdd),
        });
        const jsonartikel = await saveresponse.json();
        const newdatasetid = jsonartikel.lastInsertRowid;

        if (articlenameid !== null)
          await fetch(`/api/database/articles/dataset/name/unavailable`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(articleToAdd),
          });

        const articleEventToAdd = {};

        if (Object.keys(articleEventToAdd).length === 0) {
          Object.assign(articleEventToAdd, {
            Vorgang_ID: 1,
            Artikel_ID: newdatasetid,
            Bestellung_ID: null,
            Termin_ID: null,
            Kunden_ID: null,
            Bearbeiter_ID: bearbeiterid.Bearbeiter_ID,
          });
        }

        await fetch(`/api/database/articles/dataset/event/insert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleEventToAdd),
        });
      });

      this.$root.actionSnackbar.show({
        amount: String(amountDatasets),
        dataset: "Artikel",
        action: "importiert",
      });
      this.buttonLoading = false;
      this.newDatasets = [];
      this.defaultArticle = Object.assign({}, this.initialArticle);
      this.initialize();
    },
  },
};
</script>
